<template>
  <div class="repay">
    <BaseHeaderBar
      title="Pembayaran"
      :showCustomerService="false"
      :showBackButton="true"
    />
    <div class="content">
      <div class="top-block">
        <div>
          <p class="p1">Yang perlu dibayarkan</p>
          <p class="p2">Rp{{ formatNumberWithCommas(amount) }}</p>
          <p class="p3">Jenis pembayaran：{{ repaymentWay }}</p>
        </div>
        <img
          class="huankuan_touxiang"
          src="@/assets/img/huankuan_touxiang.png"
          alt=""
        />
      </div>
      <div class="repay-sort-list">
        <div
          v-for="item in bankList"
          :key="item.id"
          @click="selectRepaySort(item)"
        >
          <p>{{ item.name }}</p>
          <img class="arrow-right" src="@/assets/img/arrow-right.png" />
        </div>
      </div>
    </div>

    <van-popup v-model="showPicker" round position="bottom">
      <div class="picker">
        <div class="picker-toolbar">
          <div @click="showPicker = false" class="picker-cancel">
            <img src="@/assets/img/picker-back-btn.png" />
          </div>
          Pilih metode pembayaran
        </div>
        <div class="repay-sort-picker-list">
          <div
            class="item"
            v-for="item in selectedPayWay.payway"
            :key="item.pay_way"
            @click="goPay(item)"
          >
            <p class="label">{{ item.name }}</p>
            <img class="arrow-right" src="@/assets/img/arrow-right.png" />
          </div>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import Vue from 'vue'
import { Popup, Icon } from 'vant'
import request from '@/utils/request'

Vue.use(Icon)
Vue.use(Popup)
const repayTypeMap = {
  1: 'Pelunasan',
  2: 'Perpanjangan'
}

export default {
  name: 'Repay',
  data () {
    return {
      repaymentWay: '',
      amount: '',
      bankList: [],
      selectedPayWay: {},
      showPicker: false
    }
  },
  created () {
    this.getPaymentBankList()
    this.getQuery()
  },
  methods: {
    getPaymentBankList () {
      const postData = {
        redrpaymentType: this.$route.query.repayType
      }
      request.post('padrybankList', postData)
        .then(res => {
          this.bankList = res.padrymentbankList
        })
    },
    getQuery () {
      const { repayType, amount } = this.$route.query
      this.repaymentWay = repayTypeMap[repayType]
      this.amount = amount
    },
    selectRepaySort (item) {
      this.selectedPayWay = item
      this.showPicker = true
    },
    goPay (item) {
      const { repayType, amount, orderId, payID } = this.$route.query
      this.$router.push({
        path: '/pay',
        query: {
          repayType,
          amount,
          orderId,
          payID,
          channelId: this.selectedPayWay.pay_id,
          payChannel: this.selectedPayWay.pay_channel,
          name: this.selectedPayWay.name,
          payWay: item.pay_way,
          payWayName: item.name
        }
      })
    }
  }
}
</script>

<style scoped lang="less">
.repay {
}
.content {
  padding-top: 80px;
}
.top-block {
  padding-top: 34px;
  padding-left: 39px;
  padding-bottom: 16px;
  margin: 36px 60px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: url("../assets/img/huankuan_info_bg.png") no-repeat;
  background-size: 100% 100%;

  p {
    font-size: 27px;
    font-weight: normal;
    color: #fff;
  }

  .p1 {
    font-size: 24px;
    font-weight: 500;
    color: #ffffff;
    line-height: 56px;
  }

  .p2 {
    font-size: 30px;
    font-weight: normal;
    color: #ffffff;
  }

  .p3 {
    font-size: 24px;
    font-weight: 500;
    color: #ffffff;
    line-height: 56px;
  }
  img {
    width: 100px;
    height: 100px;
    margin-right: 40px;
  }
}

.arrow-right {
  width: 21px;
  height: 34px;
}

.repay-sort-list {
  margin-top: 50px;
  padding: 39px 30px 36px;
  margin-left: 30px;
  margin-right: 30px;

  & > div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 112px;
    padding-left: 20px;
    padding-right: 20px;
    border-bottom: 1px solid rgba(225, 225, 225, 0.8);
    font-size: 36px;
    font-weight: 500;
    color: #ffffff;
  }
}
.picker {
  padding: 50px 20px;
  padding-top: 30px;
  background: url("~@/assets//img/huankaunfangshi_bg.png");
  background-size: 100% 100%;
}

.picker-toolbar {
  position: relative;
  text-align: center;
  font-size: 40px;
  font-weight: bold;
  color: #ffffff;
  padding-bottom: 20px;
}

.picker-cancel {
  position: absolute;
  left: 0;
  display: flex;
  align-items: center;
  height: 100%;
  padding-left: 30px;

  cursor: pointer;

  img {
    width: 24px;
    height: 40px;
    padding-bottom: 20px;
  }
}

.repay-sort-picker-list {
  // background-color: #f4f5f6;
  height: 600px;
  width: 100%;
  padding-top: 100px;

  .item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 88px;
    line-height: 88px;
    padding-left: 24px;
    padding-right: 24px;
    margin: 0 30px;
    border-bottom: 1px solid rgba(225, 225, 225, 0.2);

    &:nth-child(n + 2) {
      margin-top: 18px;
    }

    p {
      font-size: 30px;
      color: #fff;
    }
  }
}
.van-popup {
  background: none;
}
</style>
